import { gql, useLazyQuery } from "@apollo/client";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import { loader } from "graphql.macro";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Container } from "react-modal-promise";
import { useDispatch } from "react-redux";
import { Outlet, useMatch } from "react-router-dom";
import { useTitle } from "react-use";
import useCustomNavigate from "./components/hooks/useCustomNavigate";
import MainSideMenu from "./components/MainSideMenu";
import AppsModal from "./components/modals/AppsModal";
import { ModalRoot } from "./components/modals/ModalRoot";
import NotificationsModal from "./components/modals/NotificationsModal/index";
import ProfileModalNew from "./components/modals/ProfileModal";
import SearchControl from "./components/toolbar/SearchControl";
import TimeCore from "./components/toolbar/TimeCore";
import { ModalState } from "./context/modal/ModalState";
import { useAppSelector } from "./hooks";
import { setSettings } from "./store/settingsSlice";
import { useAuth } from "./utils/useAuth";
const DASHBOARDS_QUERY = loader("./graphql/DashboardsQuery.graphql");
const USER_ID = loader("./graphql/UserIdQuery.graphql");

const GET_TIME = gql`
  query getTimezoneShift {
    getTimezoneShift
    getTimezone
    getTime
  }
`;

function MainLayout() {
  const decodedJWT = localStorage.getItem("authToken") ? jwt_decode(localStorage.getItem("authToken")) : null;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const history = useCustomNavigate();
  const { loadUser } = useAuth();
  const [loadTime, { data: timezoneShift }] = useLazyQuery(GET_TIME);
  const isFullScreen = useAppSelector((state) => state.settings.isFullScreen);
  const isViewMode = useAppSelector((state) => state.settings.isViewMode);
  const isDrawerOpen = useAppSelector((state) => state.settings.isDrawerOpen);
  const programShowCoreTime = useAppSelector((state) => state.profile?.programShowCoreTime);
  const AppTitle = useAppSelector((state) => state.profile?.programAppTitle);

  const routeMatchLogin = useMatch({ path: "/login" });
  const routeRoot = useMatch({ path: "/" });

  const [dashboardsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [userIdLoad, userIdResult] = useLazyQuery(USER_ID, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (userIdResult.data?.userId && !routeMatchLogin) {
      Promise.all([dashboardsLazyQuery(), loadUser(), loadTime()])
        .then(([_, user, time]) => {
          if (user?.programDefaultMode === "full") {
            dispatch(
              setSettings({
                timezone: time.data,
                isSideBar: false,
                isFullScreen: true,
              })
            );
          } else {
            dispatch(
              setSettings({
                timezone: time.data,
                isSideBar: true,
                isFullScreen: false,
              })
            );
          }
        })
        .finally(() => {});
    }
  }, [userIdResult.data]);

  useTitle((AppTitle as string) || "PixelBoard");

  useEffect(() => {
    if (!routeMatchLogin && decodedJWT) {
      userIdLoad().finally(() => {});
    } else {
      history("/login");
    }
  }, [routeMatchLogin]);

  useEffect(() => {
    if (userIdResult.data?.userId && routeRoot) {
      history("/boards");
    }
  }, [userIdResult.data, routeRoot]);

  const handleDrawerToggle = () => {
    dispatch(setSettings({ isDrawerOpen: false }));
  };

  if (!userIdResult.data?.userId || userIdResult.loading) return false;

  return (
    <>
      <ModalState>
        <ModalRoot />
        <CssBaseline />
        <Container />
        <Toaster position="bottom-center" />
      </ModalState>
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        {!isViewMode && (
          <AppBar color="transparent" elevation={0} position="fixed">
            <Toolbar disableGutters sx={{ marginLeft: "16px", marginRight: "24px" }}>
              {!isViewMode && (
                <>
                  <Fab
                    data-test="menu"
                    sx={{
                      width: "36px",
                      height: "36px",
                      backgroundColor: theme.palette.background.default,
                    }}
                    onClick={() => {
                      dispatch(setSettings({ isDrawerOpen: !isDrawerOpen }));
                    }}
                  >
                    <MenuIcon />
                  </Fab>
                  <SearchControl />
                </>
              )}
              {!isFullScreen && desktop && (
                <Box sx={{ marginLeft: "auto", alignItems: "center", display: "flex" }}>
                  {programShowCoreTime && <TimeCore />}
                  <NotificationsModal />
                  <AppsModal />
                  <ProfileModalNew />
                </Box>
              )}
            </Toolbar>
          </AppBar>
        )}
        <Drawer
          variant="temporary"
          anchor="left"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              width: "256px",
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <MainSideMenu
            account={!desktop}
            setDrawerOpen={() => {
              dispatch(setSettings({ isDrawerOpen: false }));
            }}
          />
        </Drawer>
        <Outlet></Outlet>
      </Box>
    </>
  );
}

export default MainLayout;
