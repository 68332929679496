import { WidgetTypesEnum } from "../../utils/widgetTypes";
import AddWidgetModal from "../AddWidgetModal";
import EditWidgetModal from "../EditWidgetModal";
import CreateGeotagsTable from "./geotags-table/CreateGeotagsTable";
import RegularChart from "./regular-chart/RegularChart";
import CreateStaticTable from "./static-table/CreateStaticTable";
import CreateTrackingTable from "./tracking-table/CreateTrackingTable";
import { TDefaultWidgetProps } from "./types";

const widgetMap = {
  [WidgetTypesEnum.DATACHART]: (props: TDefaultWidgetProps) => RegularChart(props),
  [WidgetTypesEnum.TRACKING_TABLE]: (props: TDefaultWidgetProps) => CreateTrackingTable(props),
  [WidgetTypesEnum.STATIC_TABLE]: (props: TDefaultWidgetProps) => CreateStaticTable(props),
  [WidgetTypesEnum.GEOTAGS_TABLE]: (props: TDefaultWidgetProps) => CreateGeotagsTable(props),
};

type TSelectWidgetProps = {
  group: any;
};

type TSelectByTypeParams = {
  widgetType: WidgetTypesEnum;
  name: string;
  cb?: () => void;
  widget?: any;
};

export const useSelectWidget = (props: TSelectWidgetProps) => {
  const selectByType = (params: TSelectByTypeParams) => {
    const isEdit = Boolean(params?.widget);

    switch (params.widgetType) {
      case WidgetTypesEnum.DATACHART:
        widgetMap
          .datachart({
            name: params.name,
            widget: params.widget,
            group: props.group,
          })
          .then(() => {
            params?.cb?.();
          })
          .catch();
        break;
      case WidgetTypesEnum.TRACKING_TABLE:
        widgetMap["tracking table"]({
          name: params.name,
          widget: params.widget,
          group: props.group,
        })
          .then(() => {
            params?.cb?.();
          })
          .catch();
        break;
      case WidgetTypesEnum.STATIC_TABLE:
        widgetMap["static table"]({
          name: params.name,
          widget: params.widget,
          group: props.group,
        })
          .then(() => {
            params?.cb?.();
          })
          .catch();
        break;
      case WidgetTypesEnum.GEOTAGS_TABLE:
        widgetMap["geotags table"]({
          name: params.name,
          widget: params.widget,
          group: props.group,
        })
          .then(() => {
            params?.cb?.();
          })
          .catch();
        break;
      default:
        if (isEdit) {
          EditWidgetModal({
            object: params.widget,
          })
            .then()
            .catch(() => {});
        } else {
          AddWidgetModal({
            group: props.group,
            name: params.name,
            groupId: props.group.id,
            widgetType: params.widgetType,
          })
            .then(() => {
              params?.cb();
            })
            .catch(() => {});
        }
    }
  };

  return {
    selectByType,
  };
};
