import { Backdrop, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";

const CommonModal = (props) => {
  const { modal = true, forceTitle = true } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = () => ({
    modalPaper: {
      borderRadius: "5px",
      position: "relative",
      width: props.width || "412px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        borderRadius: "0",
      },
      ...(!fullScreen ? props.paperStyles : null),
      ...props.modalPaper,
    },
    modalTitle: {
      padding: "16px",
    },
    modalContent: {
      padding: "16px",
    },
    modalActions: {
      padding: "14px 16px",
    },
  });

  const useStyles = makeStyles((theme) => styles(theme));

  const classes = useStyles();

  return (
    <>
      <Dialog
        data-test-modal={props?.title?.toLowerCase()}
        open={props.modalOpen}
        fullScreen={fullScreen}
        onClose={props.handleClose}
        className={classes.modalDialog}
        PaperProps={{
          classes: { root: classes.modalPaper },
          ...(!modal ? { style: { pointerEvents: "auto" } } : {}),
        }}
        {...(!modal ? { hideBackdrop: true } : {})}
        {...(!modal ? { disableEnforceFocus: true } : {})}
        {...(!modal ? { style: { pointerEvents: "none" } } : {})}
      >
        {
          props.loading && (
            <Backdrop
              sx={{
                color: "#fff",
                background: "#ffffff80",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
              style={{ position: "absolute" }}
            >
              <CircularProgress color="primary" />
            </Backdrop>
          )
        }

        {(fullScreen || forceTitle) && (
          <Typography
            className={classes.modalTitle}
            variant="h6"
            color="primary"
          >
            {props.title}
          </Typography>
        )}

        <DialogContent className={classes.modalContent} dividers={true}>
          {props.children}
        </DialogContent>

        {props.buttons && (
          <DialogActions className={classes.modalActions}>
            {props.buttons}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default CommonModal;
