import { ColorLens, LinkOff, Notes } from "@mui/icons-material";
import ExtensionIcon from "@mui/icons-material/Extension";
import { ListItemButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import LabelIcon from "../../icons/labelIcon";
import LinkIcon from "../../icons/LinkIcon";
import useHandleCopy from "../menu/handlers/useHandleCopy";
import { chartNavigation } from "./chartNavigation";
import AccessSection from "../basic/AccessSection";
import ServiceSection from "../basic/ServiceSection";
import DescriptionSection from "../basic/DescriptionSection";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const ChartGeneralTab = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };
  // Chart 1
  const chart1Property = () => getPropertyByKey("chart1Property");
  const chart1Source = () => getPropertyByKey("chart1Source");
  const chart1ReadyTimeseries = () => getPropertyByKey("chart1UseReady-MadeTimeseries");
  const styleChart1Color = () => getPropertyByKey("styleChart1Color");
  const styleChart1Label = () => getPropertyByKey("styleChart1Label");

  // Chart 2
  const chart2Property = () => getPropertyByKey("chart2Property");
  const chart2Source = () => getPropertyByKey("chart2Source");
  const chart2ReadyTimeseries = () => getPropertyByKey("chart2UseReady-MadeTimeseries");
  const styleChart2Color = () => getPropertyByKey("styleChart2Color");
  const styleChart2Label = () => getPropertyByKey("styleChart2Label");

  // Chart 3
  const chart3Property = () => getPropertyByKey("chart3Property");
  const chart3Source = () => getPropertyByKey("chart3Source");
  const chart3ReadyTimeseries = () => getPropertyByKey("chart3UseReady-MadeTimeseries");
  const styleChart3Color = () => getPropertyByKey("styleChart3Color");
  const styleChart3Label = () => getPropertyByKey("styleChart3Label");

  const navigate = useCustomNavigate();

  const handleCopy = useHandleCopy();

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Chart 1</Typography>
        </ListSubheader>

        {/*Chart 1*/}

        {!chart1ReadyTimeseries().value && (
          <>
            <ListItemButton
              style={{ height: "48px" }}
              onClick={() => {
                chartNavigation(chart1Source().value, navigate);
              }}
            >
              <ListItemIcon>
                {chart1Source().value.value === null && <LinkOff />}
                {chart1Source().value.value !== null && <ExtensionIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap title={chart1Source().value.title} variant="body1">
                    Object: {chart1Source().value.title}
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItem style={{ height: "48px" }}>
              <ListItemIcon>
                <LabelIcon></LabelIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap variant="body1">
                    Property: {chart1Property().value.title}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}

        {chart1ReadyTimeseries().value && (
          <ListItemButton
            style={{ height: "48px" }}
            onClick={() => {
              chartNavigation(chart1Source().value);
            }}
          >
            <ListItemIcon>
              {chart1Source().value.value === null && <LinkOff />}
              {chart1Source().value.value !== null && <LinkIcon />}
            </ListItemIcon>
            <ListItemText
              noWrap
              title={chart1Source().value.title}
              primary={<Typography variant="body1">Dataset: {chart1Source().value.title}</Typography>}
            />
          </ListItemButton>
        )}

        <ListItem style={{ height: "48px" }}>
          <ListItemIcon>
            <ColorLens></ColorLens>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Color: {styleChart1Color().value}</Typography>} />
        </ListItem>

        <ListItem style={{ height: "48px", paddingRight: "96px" }}>
          <ListItemIcon>
            <Notes></Notes>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Label: {styleChart1Label().value || "n/a"}</Typography>} />
        </ListItem>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Chart 2</Typography>
        </ListSubheader>

        {/*Chart 2*/}

        {!chart2ReadyTimeseries().value && (
          <>
            <ListItemButton style={{ height: "48px" }} onClick={() => chartNavigation(chart2Source().value, navigate)}>
              <ListItemIcon>
                {chart2Source().value.value === null && <LinkOff />}
                {chart2Source().value.value !== null && <ExtensionIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" noWrap title={chart2Source().value.title}>
                    Object: {chart2Source().value.title}
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItem style={{ height: "48px" }}>
              <ListItemIcon>
                <LabelIcon></LabelIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap title={chart2Property().value.title} variant="body1">
                    Property: {chart2Property().value.title}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}

        {chart2ReadyTimeseries().value && (
          <ListItemButton
            style={{ height: "48px", paddingRight: "96px" }}
            onClick={() => chartNavigation(chart2Source().value, navigate)}
          >
            <ListItemIcon>
              {chart2Source().value.value === null && <LinkOff />}
              {chart2Source().value.value !== null && <LinkIcon />}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" noWrap title={chart2Source().value.title}>
                  Dataset: {chart2Source().value.title}
                </Typography>
              }
            />
          </ListItemButton>
        )}

        <ListItem style={{ height: "48px", paddingRight: "96px" }}>
          <ListItemIcon>
            <ColorLens></ColorLens>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Color: {styleChart2Color().value}</Typography>} />
        </ListItem>

        <ListItem style={{ height: "48px", paddingRight: "96px" }}>
          <ListItemIcon>
            <Notes></Notes>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Label: {styleChart2Label().value || "n/a"}</Typography>} />
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Chart 3</Typography>
        </ListSubheader>

        {/*Chart 3*/}

        {!chart3ReadyTimeseries().value && (
          <>
            <ListItemButton style={{ height: "48px" }} onClick={() => chartNavigation(chart3Source().value, navigate)}>
              <ListItemIcon>
                {chart3Source().value.value === null && <LinkOff />}
                {chart3Source().value.value !== null && <ExtensionIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" noWrap title={chart3Source().value.title}>
                    Object: {chart3Source().value.title}
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItem style={{ height: "48px" }}>
              <ListItemIcon>
                <LabelIcon></LabelIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" noWrap title={chart3Property().value.title}>
                    Property: {chart3Property().value.title}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}

        {chart3ReadyTimeseries().value && (
          <ListItemButton
            style={{ height: "48px", paddingRight: "96px" }}
            onClick={() => chartNavigation(chart3Source().value, navigate)}
          >
            <ListItemIcon>
              {chart3Source().value.value === null && <LinkOff />}
              {chart3Source().value.value !== null && <LinkIcon />}
            </ListItemIcon>
            <ListItemText
              noWrap
              title={chart3Source().value.title}
              primary={<Typography variant="body1">Dataset: {chart3Source().value.title}</Typography>}
            />
          </ListItemButton>
        )}

        <ListItem style={{ height: "48px", paddingRight: "96px" }}>
          <ListItemIcon>
            <ColorLens></ColorLens>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Color: {styleChart3Color().value}</Typography>} />
        </ListItem>

        <ListItem style={{ height: "48px", paddingRight: "96px" }}>
          <ListItemIcon>
            <Notes></Notes>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Label: {styleChart3Label().value || "n/a"}</Typography>} />
        </ListItem>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default ChartGeneralTab;
