import { useApolloClient, useMutation } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { loader } from "graphql.macro";
import { useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import CommonModal from "../components/CommonModal";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import CustomSelectColor from "../components/CustomSelectColor";
import { OPERATORS } from "../constants";
import { msg } from "../messages";
import { WIDGET_QUERY } from "../queries";
import CustomSelectIcon from "./CustomSelectIcon";
import { useFormik } from "formik";
import * as yup from "yup";

const UPdATE_OBJECT_PROPERTY = loader(
  "../graphql/UpdateObjectWithProperties.graphql"
);

const COLOR_OPTIONS = [
  "default",
  "red",
  "deepRed",
  "blue",
  "green",
  "yellow",
  "yellowGreen",
  "orange",
];

const StateItem = ({ index, item, setValues, values, formik }) => {
  if (!item) {
    return false;
  }

  return (
    <>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2" color="primary">
            State {index}
          </Typography>
          <IconButton
            data-test={`state-${index}-delete`}
            variant="text"
            onClick={() => {
              formik.setValues({
                state: formik.values.state.toSpliced(index - 1, 1),
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomSelectColor
            name={`state[${index - 1}].color`}
            colors={COLOR_OPTIONS}
            label={msg.editWidgetColorsModal.objectColor}
            value={item.color ?? ""}
            onChange={formik.handleChange}
            data-test={`state-${index}-color`}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelectIcon
            name={`state[${index - 1}].icon`}
            filter={["application", "monitor", "icon"]}
            label={"Icon"}
            value={item.icon ?? ""}
            onChange={formik.handleChange}
            data-test={`state-${index}-icon`}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <CustomSelect
            data-test={`state-${index}-operator`}
            name={`state[${index - 1}].operator`}
            label={msg.editWidgetColorsModal.option}
            list={OPERATORS}
            value={item.operator ?? ""}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} alignContent="flex-end">
          <CustomInput
            data-test={`state-${index}-value`}
            name={`state[${index - 1}].value`}
            label={"Value"}
            clearFieldIcon={true}
            value={item.value}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched?.state?.[index - 1]?.value && Boolean(formik.errors.state?.[index - 1]?.value)}
            helperText={formik.touched?.state?.[index - 1]?.value && formik.errors.state?.[index - 1]?.value}
            type="string"
          />
        </Grid>
      </Grid>
    </>
  );
};

const EditWidgetColorsModal = (props) => {
  const [updateProperty] = useMutation(UPdATE_OBJECT_PROPERTY);

  const client = useApolloClient();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  let defaultValues = {};

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const validationSchema = yup.object({
    state: yup.array().of(
      yup.object().shape({
        value: yup.string().required("Value is required")
      }))
  });

  const formik = useFormik({
    initialValues: { state: [] },
    validationSchema,
    onSubmit: async () => {
      handleSave();
    },
  });

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: WIDGET_QUERY,
          variables: { objId: props.widgetId },
          fetchPolicy: "network-only",
        });

        const getPropValue = (prop) =>
          result.data.object.objectProperties.find((obj) => obj.key === prop)
            ?.value;

        defaultValues["colorsDefaultColor"] =
          getPropValue("colorsDefaultColor");
        defaultValues["settingsIcon"] = getPropValue("settingsIcon");
        await formik.setValues({ state: getPropValue("colorsState") || [] })
        setValues(defaultValues);
      } catch (err) {
        toast.error(err.toString());
      }
    };

    queryProps();
  }, []);

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const handleAddState = () => {
    formik.setValues({
      state: [
        ...formik.values.state,
        {
          color: "default",
          icon: "",
          operator: ">",
          value: "",
        },
      ],
    })
  };

  const handleSave = () => {
    const valuesReady = [
      {
        propertyKey: "colorsDefaultColor",
        value: values.colorsDefaultColor,
      },
      {
        propertyKey: "settingsIcon",
        value: values.settingsIcon,
      },
      {
        propertyKey: "colorsState",
        value: formik.values.state,
      },
    ];

    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [
                {
                  objectId: props.widgetId,
                  keyedProperties: valuesReady,
                },
              ],
            },
          },
        }),
        {
          loading: "Updating colors ...",
          success: () => `Colors updated`,
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => submit());
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetColorsModal.colorsSettings}
        handleClose={reject}
        buttons={
          <>
            <Button onClick={handleClose} color="inherit" data-test-widget-colors="close">
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              data-test-widget-colors="save"
              color="primary"
              onClick={formik.handleSubmit}
            >
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" rowSpacing={2}>
          <Grid item>
            <Typography variant="subtitle2" marginBottom={2} color="primary">
              Default state
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomSelectColor
                name="colorsDefaultColor"
                colors={COLOR_OPTIONS}
                label={msg.editWidgetColorsModal.color}
                value={values["colorsDefaultColor"] ?? ""}
                onChange={handleInputChange}
                data-test="default_color"
              />
            </Grid>

            <Grid item xs={6}>
              <CustomSelectIcon
                filter={["application", "monitor", "icon"]}
                name="settingsIcon"
                label={"Icon"}
                value={values["settingsIcon"] ?? ""}
                onChange={handleInputChange}
                data-test="default_icon"
              />
            </Grid>
          </Grid>

          {formik.values.state.map((item, index) => (
            <StateItem
              item={item}
              index={index + 1}
              values={values}
              formik={formik}
              setValues={setValues}
            />
          ))}

          <Grid item>
            <Button
              data-test={"addState"}
              variant="text"
              startIcon={<AddIcon />}
              onClick={handleAddState}
            >
              Add state
            </Button>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetColorsModal);
