import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { AvTimer } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import ExtensionIcon from "@mui/icons-material/Extension";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import ParameterDateRange from "./modals/ParameterDateRange";
import ParameterObjectModal from "./modals/ParameterObjectModal";
import ParameterPeriod from "./modals/ParameterPeriod";
import StaticTableColumnsModal from "./modals/StaticTableColumnsModal";
import RpcSubscribeWrapper from "./RpcSubscribeWrapper";
import AccessSection from "../basic/AccessSection";
import ServiceSection from "../basic/ServiceSection";
import DescriptionSection from "../basic/DescriptionSection";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import SelectSchema from "../../modals/SelectSchema";
import TuneIcon from "@mui/icons-material/Tune";
import EditDynamicProperty from "../../modals/EditDynamicProperty";

const UPDATE_PROPERTIES_BY_IDS = gql`
    mutation updateObjectPropertiesById($input: UpdateObjectPropertiesByIdInput!) {
        updateObjectPropertiesById(input: $input) {
            clientMutationId
        }
    }
`

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      clientMutationId
      controlExecution {
        id
      }
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_SCHEMA = gql`
    query getSchema($id: UUID!) {
        schema(id: $id) {
            id
            name
        }
    }
`;

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const StaticTableGeneral = (props) => {
  const { item } = props;
  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const navigate = useCustomNavigate();

  const columns = () => {
    const prop = item.objectProperties.find((item) => item.key === "settingsColumns").value.columns;

    if (prop.length) {
      return prop.map((item) => ({ alias: item?.alias || '', ...item?.value, mode: item.mode, }));
    }

    return [];
  };

  const modes = () => {
    const prop = item.objectProperties.find((item) => item.key === "settingsColumns").value.columns;

    if (prop.length) {
      return prop.map((item) => item?.mode);
    }

    return [];
  };

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  const settingsColumns = () => {
    return item.objectProperties.find((item) => item.key === "settingsColumns");
  };

  const valueValue = () => {
    return item.objectProperties.find((item) => item.key === "valueValue");
  };

  const settingsObject = () => {
    return item.objectProperties.find((item) => item.key === "settingsObject");
  };

  const settingsLinkedOnly = () => {
    return item.objectProperties.find((item) => item.key === "settingsLinkedOnly");
  };

  const settingsPeriod = () => {
    return item.objectProperties.find((item) => item.key === "settingsPeriod");
  };

  const settingsDateRange = () => {
    return item.objectProperties.find((item) => item.key === "settingsDateRange");
  };

  const settingsSchema = () => {
    return item.objectProperties.find((item) => item.key === "settingsSchema");
  };

  const linkedObjects = () => {
    return item.objectsToObjectsByObject1Id.map(item => ({ value: item.object2.id, title: item.object2.name }));
  };

  const [loadSchema, { loading: isLoadingSchema, error, data }] = useLazyQuery(GET_SCHEMA);

  const schemaName = () => {
    return data?.schema?.name || "n/a";
  };

  const [parameterObject, setParameterObject] = useState(settingsObject().value);
  const [parameterDateRange, setParameterDateRange] = useState(settingsDateRange().value);
  const [parameterPeriods, setParameterPeriods] = useState(settingsPeriod().value);
  const [isMayGetData, setIsMayGetData] = useState(false);


  useEffect(() => {
    if (!settingsSchema().value || !settingsDateRange().value.value || !settingsColumns().value.columns.length) {
      setIsMayGetData(false);
    } else {
      setIsMayGetData(true);
    }
  }, [item]);

  const handleDelete = (conditionIndex) => {
    const conditionPropertyItem = item.objectProperties.find((item) => item.key === "settingsColumns");

    const valueProperty = item.objectProperties.find((item) => item.key === "valueValue");
    const oldValues = cloneDeep(conditionPropertyItem.value.columns.map((item) => item));

    oldValues.splice(conditionIndex, 1);

    let patch = {
      columns: [...oldValues],
    };

    updateProperty({
      variables: {
        input: {
          id: conditionPropertyItem.id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {
      if (patch.columns.length === 0) {
        return updateProperty({
          variables: {
            input: {
              id: valueProperty.id,
              patch: {
                value: [],
              },
            },
          },
        });
      }
    });
  };

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "UpdateReport",
            objectId: item.id,
            params: {
              object: parameterObject.value,
              period: parameterPeriods.value,
              range: parameterDateRange.value,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (settingsSchema().value) {
      loadSchema({
        variables: {
          id: settingsSchema().value
        }
      });
    }
  }, [item]);

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Parameters</Typography>
        </ListSubheader>
        <ListItem
          classes={{ container: classes.itemToHover }}
          style={{ height: "48px" }}
        >
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Schema: { schemaName() }</Typography>}
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                SelectSchema({
                  handleSave: (id, value) => {
                    return updateProperties({
                      variables: {
                        input: {
                          propertiesArray: [
                            {
                              propertyId: id,
                              value: value
                            },
                            {
                              propertyId: settingsObject().id,
                              value: { title: "n/a", value: null }
                            },
                            {
                              propertyId: settingsColumns().id,
                              value: { columns: [] }
                            },
                            {
                              propertyId: valueValue().id,
                              value: []
                            }
                          ]
                        },
                      },
                    })
                  },
                  id: settingsSchema().id,
                  value: settingsSchema().value,
                })
                  .then()
                  .catch();
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "96px" }}
        >
          <ListItemIcon>
            <ExtensionIcon></ExtensionIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Object: {settingsObject().value.title}</Typography>}
            onClick={(e) => {
              navigate(settingsObject().value.value);
            }}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              size="small"
              onClick={() => {
                ParameterObjectModal({
                  id: item.id,
                  settingsLinkedOnly: settingsLinkedOnly(),
                  linkedObjects: linkedObjects(),
                  schemaId: data?.schema?.id || null,
                  objectId: settingsObject()?.value?.value || null,
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsObject().id,
                          patch: {
                            value: object || { title: "n/a", value: null },
                          },
                        },
                      },
                    }).then();
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "96px" }}
        >
          <ListItemIcon>
            <EventIcon></EventIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Date: {settingsDateRange().value.title}</Typography>}
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              size="small"
              onClick={() => {
                ParameterDateRange({
                  selected: settingsDateRange(),
                  save: (date) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsDateRange().id,
                          patch: {
                            value: date,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "96px" }}
        >
          <ListItemIcon>
            <AvTimer></AvTimer>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Aggregation: {settingsPeriod().value.title}</Typography>}
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              onClick={() => {
                ParameterPeriod({
                  selected: settingsPeriod(),
                  save: (period) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsPeriod().id,
                          patch: {
                            value: period,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{ container: classes.itemToHover }}
          style={{ height: "48px" }}
        >
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                Linking: {settingsLinkedOnly().value ? "Linked only" : "All"}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              size="small"
              edge="end"
              onClick={() => {
                const target = settingsLinkedOnly();
                EditDynamicProperty({
                  handleSave: handleUpdateProperty,
                  property: target,
                  id: target.id,
                  value: target.value,
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Columns</Typography>
        </ListSubheader>
        {columns().map((item, index) => {
          return (
            <ListItem
              classes={{
                container: classes.itemToHover,
              }}
              style={{ height: "48px", paddingRight: "96px" }}
              button
              onClick={() => {}}
              key={index}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">
                  {item?.alias ? `${item?.alias} (${item.mode})`: `${item.title  || "n/a"} (${item.mode})`}
                </Typography>}
                onClick={(e) => {}}
              />
              <ListItemSecondaryAction className={classes.itemToHideOrShow}>
                <IconButton
                  size="small"
                  onClick={() => {
                    StaticTableColumnsModal({
                      parameterSchema: settingsSchema().value,
                      settingsPeriod: settingsPeriod().value,
                      columns: settingsColumns(),
                      isEdit: true,
                      condition: columns()[index],
                      mode: modes()[index],
                      conditionIndex: index,
                    })
                      .then()
                      .catch(() => {});
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem
          style={{ height: "48px" }}
          button
          onClick={() => {
            StaticTableColumnsModal({
              settingsPeriod: settingsPeriod().value,
              parameterSchema: settingsSchema().value,
              columns: settingsColumns(),
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Add column</Typography>} />
        </ListItem>

        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={"UpdateReport"}
            objectId={item.id}
            disabled={!isMayGetData}
            object={item}
            title={"Update table"}
            handler={rpcHandler}
          >
            <LoadingButton fullWidth={true} variant={"outlined"}></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default StaticTableGeneral;
